import '../styles/Home.scss';

function About() {
    return (
        <section className='about-me-container'>
            <div className='about-me'>
                <p className='big-font'>
                    <span>Hi,</span><br />
                    <span>my name is</span><br />
                    <span>"𝓜𝓸𝓶𝓪𝓻 🌈☀️".</span>
                </p>
                <p className='small-text'>
                    I am a programmer. I started programming at 15 years old, and I am currently building and designing things for the web. In 2015, I moved to the US 🇺🇸 and earned my BS in Computer Science at Saint Joseph's University in 2019. In 2017, I co-founded <a href='#NXTDROP'>NXTDROP</a>, an online sneaker marketplace. Then, I moved to Canada 🇨🇦 in 2019 to scale the business, and we've sold more than $1M worth of sneakers since then.
                </p>
            </div>
        </section>
    );
}

export default About;