import '../styles/Projects.scss';
import { ReactComponent as GithubIcon } from '../assets/github-logo.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import PageHeader from '../components/PageHeader';

function Projects() {
    return (
        <section className="projects-container">
            <PageHeader title="Projects" />
            <div className='projects'>
            <div className='project'>
                    <div className='metadata'>
                        <span className='title'>Pink Channel Obsidian Theme</span>
                        <span className='date'>Jul 2022 - Sep 2022</span>
                    </div>
                    <div className='description'>
                        <div>
                            <p>As a heavy <a href='https://obsidian.md' target='_blank' rel='noreferrer'>Obsidian</a> user, I customized my workspace with a theme inspired by this website.</p>

                            <p>Download the theme from my GitHub page to try it out.</p>
                        </div>
                    </div>
                    <div className='tech-stack'>
                        <span>CSS</span>
                        <span>Sass</span>
                    </div>
                    <div className='external-links'>
                        <a href='https://github.com/blvckpvblo/obsidian-pink-channel' target="_blank" rel="noreferrer"><GithubIcon /></a>
                    </div>
                </div>

                <div className='project'>
                    <div className='metadata'>
                        <span className='title'>Wh.A.T. Project</span>
                        <span className='date'>Jan 2019 - Apr 2019</span>
                    </div>
                    <div className='description'>
                        <div>
                            <p>
                                For my senior project during my last year of college, I wanted to get into machine learning and neural networks.
                            </p>
                            <p>When I started NXTDROP, I wanted our company to use AI to recognize real sneakers from fake ones, so this project was a way deep dive into ML and look into how I could build a solution.</p>
                            <p>
                                As a way to introduce myself to ML and neural networks, I trained a model to recongnize a sneaker given an image and display its name.
                            </p>
                        </div>
                    </div>
                    <div className='tech-stack'>
                        <span>Python</span>
                        <span>Tensorflow</span>
                        <span>Colaboratory</span>
                        <span>LabelImg</span>
                    </div>
                    <div className='external-links'>
                        <a href='https://github.com/sju-senior-projects-2019/mc' target="_blank" rel="noreferrer"><GithubIcon /></a>
                        <a href='https://docs.google.com/presentation/d/1vOKdzSZseBeoI-IhUM0RWOwU9jpuM0ml/edit?usp=sharing&ouid=100145649239598353006&rtpof=true&sd=true' target="_blank" rel="noreferrer"><DownloadIcon /></a>
                    </div>
                </div>

                <div className='project'>
                    <div className='metadata'>
                        <span className='title'>HomeSens</span>
                        <span className='date'>Aug 2017 - Dec 2017</span>
                    </div>
                    <div className='description'>
                        <div>
                            <p>
                                HomeSense is a small prototype of a home monitoring system idea I had. Unfortunately, due to time constraints, the team I was working with did not get the chance to fully develop the idea I had in mind, but we were 75% there.
                            </p>
                            <p>
                                As society get more tech-enabled, more items inside our home will be connected to the internet. So the idea behind HomeSense was to design and develop a few devices that can monitor rooms temperature, turn lights on/off and open garage/front door via an app, etc.
                            </p>
                            <p>
                                Because we could not really build a full scale product, we decided to a prototype using an Arduino and cheap sensors.
                            </p>
                        </div>
                    </div>
                    <div className='tech-stack'>
                        <span>Arduino</span>
                        <span>PHP</span>
                        <span>INO</span>
                        <span>HTML</span>
                        <span>CSS</span>
                    </div>
                    <div className='external-links'>
                        <a href='https://github.com/blvckpvblo' target="_blank" rel="noreferrer"><GithubIcon /></a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;