import '../styles/Companies.scss';
import PageHeader from '../components/PageHeader';

function Work() {
    return (
        <section className='work-exp-container'>
            <PageHeader title="Work" />
            <div className='ventures'>
            <div className='experience'>
                    <div className='information'>
                        <div>
                            <span className='title'>Co-Founder & Imperator</span>
                            <span className='date'>Jun 2023 - Present</span>
                        </div>
                        <div>
                            <span className='company'><a>Rumiya Co.</a></span>
                            <span className='location'>Dakar</span>
                        </div>
                    </div>
                    <div className='description'>
                        <div>
                            <p>We are merchants of dreams, and we wholeheartedly embrace the virtue of creation, considering it our duty to leave this world better than we found it.</p>
                            <p>More to come later this year.</p>
                        </div>
                    </div>
                </div>

                <div className='experience'>
                    <div className='information'>
                        <div>
                            <span className='title'>Software Engineer</span>
                            <span className='date'>Nov 2022 - Present</span>
                        </div>
                        <div>
                            <span className='company'><a href='https://atos.net/en' target='_blank' rel='noreferrer'>Atos</a></span>
                            <span className='location'>Dakar</span>
                        </div>
                    </div>
                    <div className='description'>
                        <div>
                            <p>I am currently working on a project for an Atos client in a distributed manner with developers based in Morocco and France. I am developing user-facing (frontend) and backend software using tools like Angular, Java, and CouchDB. I am in a team developing software meant to be used internally by the client's employees.</p>
                            <p>At the same time, I am also working part-time on an internal tool for Atos.</p>
                            <p>My role is to come up with software solutions to solve Atos' and its clients' problems.</p>
                        </div>
                    </div>
                </div>

                <div className='experience'>
                    <div className='information'>
                        <div>
                            <span className='title'>Co-founder</span>
                            <span className='date'>Jun 2017 - Apr 2022</span>
                        </div>
                        <div>
                            <span className='company'><a>NXTDROP, Inc.</a></span>
                            <span className='location'>Toronto, Philadelphia & Calgary</span>
                        </div>
                    </div>
                    <div className='description'>
                        <div>
                            <p>NXTDROP is a sneaker marketplace connecting sneakerheads and resellers based in Calgary. And, unlike other marketplaces like Kijiji or eBay, we authenticate every pair because of counterfeits, which is a trillion-dollar industry with sneakers being the most counterfeited goods. Our authentication system ensures that people don't lose thousands of dollars buying sneakers online.</p>
                            <p>I started this company after spending the summer of 2017 <a href='https://fi.co/s/toronto' target="_blank" rel='noreferrer'>@ Founder Institute Toronto</a>, a pre-seed startup accelerator.</p>
                            <p>Designed, developed, and launched a web application visited by over 5,000 users monthly. Integrated third-party APIs to the web application (Stripe, etc.). Maintained the web application and managed customer service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Work;