import '../styles/Archives.scss';
import PageHeader from '../components/PageHeader';

function Archives() {
    return (
        <section className="archives-container">
            <PageHeader title="Archives" />
            <div className="archives">
                <div className='year-list'>
                    <h3>2021</h3>
                    <div className='archive'>
                        <div className='metadata'>
                            <h6>NXTDROP - Telling It Like It Is</h6>
                            <div className='tags'>
                                <button>Interview</button>
                                <button>NXTDROP</button>
                            </div>
                        </div>
                        <div className='iframe-container'>
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FPeerGuidanceYYC%2Fvideos%2F2958863564435728%2F&show_text=false&width=560&t=0" allowFullScreen={true} allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>
                        </div>
                        <div className='location'>
                            <p>Apr 14, 2021</p>
                            <p>Calgary</p>
                        </div>
                    </div>
                </div>
                <div className='year-list'>
                    <h3>2020</h3>
                    <div className='archive'>
                        <div className='metadata'>
                            <h6>Calgary's Next Economy: NXTDROP wants your vintage athletic sneakers</h6>
                            <div className='tags'>
                                <button>Interview</button>
                                <button>NXTDROP</button>
                            </div>
                        </div>
                        <div className='description'>
                            <p>Please read the interview <a href='https://livewirecalgary.com/2020/04/09/calgarys-next-economy-nxtdrop-wants-your-vintage-athletic-sneakers/' target='_blank' rel='noreferrer'>here</a>.</p>
                        </div>
                        <div className='location'>
                            <p>Mar 11, 2020</p>
                            <p>Calgary</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Archives;